import React from 'react';

import {
    article,
    hidden as hiddenClass,
    heading,
    content,
    mobileLink,
    desktopLink,
} from './company-card.module.scss';
import { ICompany } from '../../models/company.model';
import useTranslations from '../../hooks/use-translations';

import Heading from '../atoms/heading';
import Content from '../atoms/content';
import Button from '../atoms/button';
import ContactDetails from './contact-details';
import KeywordsListing from './keywords-listing';

interface ICompanyCardProps {
    className?: string;
    company: ICompany;
    keywordOnClick?: (tag: string) => void;
}

const CompanyCard: React.FC<ICompanyCardProps> = ({
    className = '',
    company,
    keywordOnClick = () => {},
}) => {
    const t = useTranslations('CompanyCard');
    const { name, lead, pathname, tags, contacts, active } = company;

    const primaryContact = contacts.find((contact) => contact.isPrimary);

    return (
        <article
            className={`${className} ${article} ${
                !active && active !== undefined ? hiddenClass : ''
            }`}
        >
            <div>
                <Heading numberOfStyle={5} className={heading}>
                    {name}
                </Heading>
                {lead && <Content className={content}>{lead}</Content>}
                {tags && (
                    <KeywordsListing
                        keywords={tags}
                        areKeywordsClickable={true}
                        keywordTheme={'light'}
                        keywordOnClick={keywordOnClick}
                    />
                )}
                <Button
                    as={'link'}
                    to={pathname}
                    theme={'dark'}
                    className={desktopLink}
                    isOutlined={true}
                    aria-label={t.buttonAccessible({ name })}
                >
                    {t.button}
                </Button>
            </div>
            {primaryContact && (
                <ContactDetails
                    contacts={primaryContact}
                    showHeading={false}
                    showLabel={false}
                    showEmails={false}
                />
            )}
            <Button
                as={'link'}
                to={pathname}
                theme={'dark'}
                className={mobileLink}
                isOutlined={true}
                aria-label={t.buttonAccessible({ name })}
            >
                {t.button}
            </Button>
        </article>
    );
};

export default CompanyCard;
