import React from 'react';

import {
    list,
    loading as loadingClass,
    group,
    hide as hideClass,
    label,
    loader,
} from './company-card-listing.module.scss';
import { ICompany } from '../../models/company.model';
import { getElementsGroups } from './company-categories-group-list';
import useTranslations from '../../hooks/use-translations';

import AccessibleContent from '../atoms/accessible-content';
import CompanyCard from './company-card';
import Loader from '../atoms/loader';

interface ICompanyCardListingProps {
    className?: string;
    companies: ICompany[];
    isLoading?: boolean;
    keywordOnClick?: (tag: string) => void;
}

const COLUMNS_AMOUNT = 1;

const CompanyCardListing: React.FC<ICompanyCardListingProps> = ({
    className = '',
    companies,
    isLoading = false,
    keywordOnClick = () => {},
}) => {
    const t = useTranslations('CompanyCardListing');
    const companyGroups = getElementsGroups(companies, COLUMNS_AMOUNT)[0];

    return (
        <section className={`${list} ${className} ${isLoading ? loadingClass : ''}`}>
            {companyGroups.map((companyGroup) => {
                const [letter, companies] = companyGroup;

                const searchFalse = companies.filter((company) => {
                    return company.active === false;
                });

                const allFalse = companies.length === searchFalse.length;

                return (
                    <div
                        key={`company-card-wrapper-${letter}`}
                        className={`${group} ${allFalse ? hideClass : ''}`}
                    >
                        <AccessibleContent
                            isRegular={false}
                            numberOfStyle={1}
                            screenReadersContent={t.label({ letter })}
                            className={label}
                        >
                            {letter}
                        </AccessibleContent>
                        {companies.map((company) => (
                            <CompanyCard
                                key={company.companyId}
                                company={company}
                                keywordOnClick={keywordOnClick}
                            />
                        ))}
                    </div>
                );
            })}
            {isLoading && <Loader className={loader} />}
        </section>
    );
};

export default CompanyCardListing;
