import React, { useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import { useMutation } from 'react-query';

import { container, grid, heading, subheading } from './company-listing.module.scss';

import { ICompanyCategory } from '../models/company-category.model';
import { ICompany } from '../models/company.model';
import { searchCompany } from '../api-ssr/search';
import useTranslations from '../hooks/use-translations';
import useQueryParam from '../hooks/use-query-param';

import MainLayout from '../layouts/main-layout';
import Heading from '../components/atoms/heading';
import CompanyCardListing from '../components/molecules/company-card-listing';
import Content from '../components/atoms/content';

interface ICompanyListingQueryResults {
    companyCategory: ICompanyCategory;
}

interface ICompanyListingProps {
    data: ICompanyListingQueryResults;
}

const CompanyListing: React.FC<ICompanyListingProps> = ({ data }) => {
    const t = useTranslations('CompanyListing');
    const { name, companies } = data.companyCategory;
    const [companiesData, setCompaniesData] = useState(companies);
    const [isEmpty, setIsEmpty] = useState(false);

    const tagsParam = useQueryParam('tags[]');
    const tagsParamValues = tagsParam.values;
    const searchParam = useQueryParam('phrase');
    const searchParamValue = searchParam.values[0];

    const mutation = useMutation(searchCompany, {
        onSuccess: (data: ICompany[]) => {
            setIsEmpty(data.length < 1);
            setCompaniesData((prevData) => {
                return prevData.map((company) => {
                    const found = data.find((result) => result.companyId === company.companyId);
                    return { ...company, active: !!found };
                });
            });
        },
    });

    const { isLoading, isError } = mutation;

    const resetResults = () => {
        setIsEmpty(false);
        setCompaniesData((prevCompanies) => {
            return prevCompanies.map((company) => {
                return { ...company, active: undefined };
            });
        });
    };

    useEffect(() => {
        if (searchParamValue || tagsParamValues.length) {
            mutation.mutate({ phrase: searchParamValue, tags: tagsParamValues });
        } else {
            resetResults();
        }
    }, [searchParamValue, tagsParamValues]);

    const addTag = (tag: string) => {
        tagsParam.addValue(tag);
    };

    const removeTag = (tag: string) => {
        tagsParam.removeValue(tag);
    };

    const handleSubmit = (searchQuery: string) => {
        if (searchQuery) {
            searchParam.setValue(searchQuery);
        } else {
            searchParam.removeValue();
        }
    };

    return (
        <MainLayout
            additionalSearch={{
                type: 'company-list',
                handleSubmit: handleSubmit,
                phrase: searchParamValue,
                tags: tagsParamValues,
                tagOnClick: removeTag,
            }}
        >
            <div className={grid}>
                <div className={container}>
                    <Heading Tag={'h1'} numberOfStyle={2} className={heading}>
                        {name}
                    </Heading>
                    {(isError || isEmpty) && (
                        <Content className={subheading} numberOfStyle={1}>
                            {isError ? t.error : t.empty}
                        </Content>
                    )}
                    <CompanyCardListing
                        isLoading={isLoading}
                        companies={companiesData}
                        keywordOnClick={addTag}
                    />
                </div>
            </div>
        </MainLayout>
    );
};

export const query = graphql`
    query ($categoryId: Int) {
        companyCategory(categoryId: { eq: $categoryId }) {
            ...companyCategoryFields
            companies {
                ...companyFields
            }
        }
    }
`;

export default CompanyListing;
